import { useNavigate } from 'react-router-dom'


const Home = () => {
  const navigate = useNavigate();
  return (
    <p>Inicio</p>
  )
};

export default Home;