import React, { useRef, useContext } from 'react';
import Logo from '../assets/img/logo-oha-education.png'
import Fondo from '../assets/background/fondo-logo-oha-education.png'
import { ArrowBack, Line } from '../assets/icons/Icons';
import AppContext from '../context/AppContext';
import { AUTH_OHA, API_OHA } from '../utils/urlConfig'
import Loading from '../components/Loading';
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios'

const PasswordRecovery = () => {
    const form = useRef(null)
    const navigate = useNavigate()
    const { loading, setLoading } = useContext(AppContext)
    const { register, formState: { errors }, handleSubmit } = useForm();

    const recovery = async (event) => {
        console.log("paso por aca")
        event.preventDefault();
        const formData = new FormData(form.current);
        const data = {
            recoveryData: formData.get('email')
        }
        console.log(data)

        if (!data.recoveryData) {
            alert('Por favor ingresa el dato de recuperacion')
        } else {
            try{
                await axios.get(AUTH_OHA,{email:data.username ,password: data.password})
                    .then(response => {
                        if(response.status==='False'){
                            if(response.message === 'Incorrect username and/or password'){
                                alert(response.message)
                                return
                            }
                            if(response.message === 'User not active'){
                                alert('Access denied, please confirm your profile')
                                return
                            }
                        }else{
                            localStorage.setItem('token', JSON.stringify(response.token))
                            navigate('/profile')
                        }
                    })
            }catch(err){
                console.error(err)
            }
        }

    }
    return (
        <div className="relative font-[lufga-light bg-repeat-y bg-center bg-contain flex flex-col items-center justify-evenly h-screen overflow-hidden">
            <div className='absolute left-0 ml-[10px] top-5'>
                <Link to="/login">
                    <ArrowBack />
                </Link>
            </div>
            {!loading && <div className='absolute rotate-[-25deg] top-12 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading && <div className='absolute rotate-[-25deg] top-[15vh] right-22 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading &&
                <div className='relative flex flex-col items-center justify-center h-[100vh] w-[100vw]'>
                    <div>
                        <img className='w-[200px]' src={Logo} alt='logo' />
                    </div>
                    <div className='absolute bottom-16 left-[44vw] sm:relative sm:left-0 sm:bottom-0 sm:top-5'>
                        <Loading />
                    </div>
                </div>}

            {!loading && <div className='mb-[0px] sm:mt-[90px] mt-[90px]'>
                <button type='button' onClick={() => navigate('/')}>
                    <img className='w-[200px]' src={Logo} alt='logo' />
                </button>
            </div>}

            {!loading && <div className='sm:mt-[50px] mt-[30px] relative bg-white w-screen h-[100%] flex flex-col items-center rounded-[25px] shadow-[0_-5px_5px_0_rgba(0,0,0,0.3)]'>
                <div className='sm:mt-[50px] flex flex-col items-center mb-[0px] mt-[50px] sm:mt-[35px]'>
                    <h1 className='font-semibold text-xl font-sans sm:text-2xl'>Recupera tu contraseña</h1>
                    <p className='sm:text-sm text-xs mt-[8px] mb-[3px] text-neutral-500 sm:w-[450px] w-[300px] text-center'>Ingresa tu correo electronico asociado a tu cuenta para recuperar tu contraseña</p>
                    <div className='z-40'>
                        <Line />
                    </div>
                </div>
                <form onSubmit={handleSubmit(recovery)} className='flex flex-col mb-[30px] items-center w-full' ref={form}>
                    <div className='flex flex-col items-center w-full sm:items-start  sm:w-1/4 sm:mx-[15px]'>
                        <fieldset className={`border-[1px] border-neutral-800 rounded-xl w-10/12 sm:w-full focus-within:border-amber-400 ${errors.recovery && "invalid"}`}>
                            <legend className='rounded text-sm ml-[15px] px-1'>Correo</legend>
                            <input
                                type="text"
                                id="email"
                                className='w-full h-full border-neutral-800 focus:outline-0 rounded-xl px-[20px] pt-[0px] pb-[10px] sm:w-full'
                                {...register("recovery", { required: "El campo es requerido." })}
                                placeholder="Ingrese su correo electrónico"
                            />
                        </fieldset>
                        {errors.recovery && (
                            <small className="text-danger">{errors.recovery.message}</small>
                        )}
                    </div>
                    <div className='flex justify-center my-5'>
                        <button className='bg-sky-700 sm:text-sm text-xs text-center sm:mt-[30px] mt-[5px] text-white rounded-[50px] my-[5px] py-[5px] px-[20px] font-bold' type='submit'>Recuperar</button>
                    </div>
                </form>
            </div>}
        </div>
    );
};

export default PasswordRecovery;