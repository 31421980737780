import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Home from "../pages/Home";
import Confirm from "../pages/Confirm";
import PasswordRecovery from "../pages/PasswordRecovery";
import PasswordRestore from "../pages/PasswordRestore";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route >
                    <Route exact path='/' element={<Home />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path='/confirm' element={<Confirm />} />
                    <Route exact path="/recovery" element={<PasswordRecovery />} />
                    <Route exact path="/restore" element={<PasswordRestore />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
