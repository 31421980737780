import { deepMerge } from "grommet-icons/utils";
import { base, Previous, Subtract, View} from "grommet-icons";
import { css, ThemeProvider } from "styled-components";

const customColorTheme = deepMerge(base, {
  global: {
      colors: {
      icons: "#333333"
      }
  },
  icon: {
      extend: css`
      ${props =>
          props.color === "sunny" &&
          `
      fill: #FCB501;
      stroke: #FCB501;
      `}
      `
  }
  });


const ArrowBack = () => (
  <ThemeProvider theme={customColorTheme}>
    <Previous size="30px" color="#FCB501"/>
  </ThemeProvider>
)

const Line = () => (
  <ThemeProvider theme={customColorTheme}>
    <Subtract size="30px" color="#FCB501"/>
  </ThemeProvider>
)

const CheckPassword = () => (
  <View size="20px"/>
)

export {
  ArrowBack,
  Line,
  CheckPassword
}