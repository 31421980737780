import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormAutoComplete, FormPassword, FormDropDownList, FormInputCapitalize } from '../data/form-components';
import { firstNameValidator, lastNameValidator, passwordValidator, userNameValidator } from '../data/validators';
import { countries } from '../data/data';
import { requiredValidator } from '../data/validators';

export const DatosEscenciales = <div>
  <div className='flex flex-col items-center sm:flex-row py-[5px]'>
    <div className='flex flex-col items-center w-full sm:items-start sm:pr-[7px] pr-[0px]'>
      <Field key={'first_name'} id={'first_name'} name={'first_name'} label={'Nombre(s)'} type={'text'} component={FormInputCapitalize} validator={firstNameValidator} placeholder="Ingrese su nombre" />
    </div>

    <div className='flex flex-col items-center w-full sm:items-start sm:pl-[7px] pl-[0px]'>
      <Field key={'last_name'} id={'last_name'} name={'last_name'} label={'Apellido(s)'} type={'text'} component={FormInputCapitalize} validator={lastNameValidator} placeholder="Ingrese su apellido" />
    </div>
  </div>

  <div className='flex flex-col items-center sm:flex-row py-[5px]'>
    <div className='flex flex-col items-center w-full sm:items-start sm:pr-[7px] pr-[0px]'>
      <Field key={'username'} id={'username'} name={'username'} label={'Usuario'} type={'text'} component={FormInput} validator={userNameValidator} placeholder="Ingrese su nombre de usuario" />
    </div>

    {/* trae valores desde data.jsx */}
    <div className='flex flex-col items-center w-full sm:items-start sm:pl-[7px] pl-[0px]'>
      <Field key={'country'} id={'country'} name={'country'} label={'País'} component={FormDropDownList} data={countries} validator={requiredValidator} placeholder="Ingrese su país" />
    </div>
  </div>

  <div className='flex flex-col items-center sm:flex-row py-[5px]'>
    <div className=' w-full sm:items-start sm:pr-[7px] pr-[0px]'>
      <Field key={'password'} id={'password'} name={'password'} label={'Password'} type={'password'} component={FormPassword} validator={passwordValidator} placeholder="Ingrese su contraseña" />
    </div>

    <div className='flex flex-col items-center w-full sm:items-start sm:pl-[7px] pl-[0px]'></div>
  </div>
</div>;