import React, { useRef, useState, useContext } from 'react';
import AppContext from '../context/AppContext';
import Fondo from '../assets/background/fondo-logo-oha-education.png'
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import Logo from '../assets/img/logo-oha-education.png'
import { Link } from 'react-router-dom';
import { DatosEscenciales } from "../containers/datos-escenciales";
import { DatosAdicionales } from "../containers/datos-adicionales";
import { TerminosCondiciones } from "../containers/terminos-condiciones";
import { Line } from '../assets/icons/Icons';
import { AUTH_OHA, API_OHA } from '../utils/urlConfig'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import '../assets/css/register.css'

export const Register = () => {
  const stepPages = [DatosEscenciales, DatosAdicionales, TerminosCondiciones];
  const navigate = useNavigate()
  const { loading, setLoading } = useContext(AppContext)
  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = React.useState({});
  const [steps, setSteps] = React.useState([
    {
      label: "Datos escenciales",
      isValid: undefined,
    },
    {
      label: "Datos adicionales",
      isValid: undefined,
    },
    {
      label: "Términos y condiciones",
      isValid: undefined,
    },
  ]);
  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const onStepSubmit = React.useCallback(event => {
    const {
      isValid,
      values
    } = event;
    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));
    setSteps(currentSteps);

    if (!isValid) {
      return;
    }

    setStep(() => Math.min(step + 1, lastStepIndex));
    setFormState(values);

    const setRegisterData = async () => {
      console.log(values)
      const email = values.email
      const username = values.username
      const password = values.password
      localStorage.setItem('ohaActivationEmail', JSON.stringify(email))
      localStorage.setItem('ohaUserName', JSON.stringify(username))
      localStorage.setItem('ohaPassword', JSON.stringify(password))
      try {
        const response = await axios.post(`${AUTH_OHA}token/?create=true`, values)
        console.log(response)
        const data = response.data
        console.log(data)
        navigate('/confirm')
      } catch (err) {
        console.log(err.response)
      }
    }

    if (isLastStep) {
      //alert(JSON.stringify(values));
      setRegisterData()
    }

  }, [steps, isLastStep, step, lastStepIndex]);

  const onPrevClick = React.useCallback(event => {
    event.preventDefault();
    setStep(() => Math.max(step - 1, 0));
  }, [step, setStep]);

  return (
    <div className="relative font-[lufga-light] bg-repeat-y bg-top bg-contain flex flex-col items-center">
      {!loading && <div className='absolute z-[-10] h-[100vh] w-full overflow-hidden'>
        <img className='absolute rotate-[-25deg] opacity-10 object-contain' src={Fondo} alt='fondo' />
      </div>}
      {loading && <div className='absolute rotate-[-25deg] top-[15vh] right-22 z-[-10] overflow-hidden'>
        <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
      </div>}
      {loading &&
        <div className='relative flex flex-col items-center justify-center h-[100vh] w-[100vw]'>
          <div>
            <img className='w-[200px]' src={Logo} alt='logo' />
          </div>
          <div className='absolute bottom-16 left-[44vw] sm:relative sm:left-0 sm:bottom-0 sm:top-5'>

          </div>
        </div>}
      {!loading && <div className='relative bottom-5 sm:mb-[0px] mb-[30px] mt-[70px] sm:mt-[60px]'>
        <Link to="/">
          <img className='w-[200px]' src={Logo} alt='logo' />
        </Link>
      </div>}
      {!loading && <div className='bg-white relative top-14 w-full h-full h-[100%] flex flex-col items-center rounded-[25px] shadow-[0_-5px_5px_0_rgba(0,0,0,0.3)] sm:bottom-0 sm:top-[20px] top-[0px]'>

        <div className='flex flex-col justify-center w-[100%]'>

          <div className='flex flex-col items-center mb-[0px] mt-[20px] sm:mt-[30px]'>
            <h1 className='font-semibold text-xl font-sans sm:text-2xl'>Crear tu cuenta de Oha</h1>
            <p className='text-base sm:text-base mt-[1px] mb-[5px] text-neutral-500'>Llena los datos para comenzar</p>
            <div className='z-40'>
              <Line />
            </div>
          </div>
          <Form
            className='flex flex-col items-center w-full sm:mt-[15px] mt-[5px]'
            initialValues={formState}
            onSubmitClick={onStepSubmit}
            render={formRenderProps =>
              <div className='w-full flex flex-col flex-direction: row;' id="main">
                <Stepper className='mb-[20px]' value={step} items={steps} />
                <FormElement>
                  {stepPages[step]}
                  <div class="center tabs">
                    <div>
                      {step !== 0 ?
                        <Button onClick={onPrevClick} className='mr-[16px] bg-white rounded-[50px] py-[4px] px-[30px] text-center text-sky-700 font-bold active:translate-y-[5px] mt-2 sm:text-[15px] sm:px-[10px] sm:mx-[10px] sm:w-28'>
                          Anterior
                  </Button>
                        : undefined}

                      <Button themeColor={'tertiary'} className='bg-sky-700 rounded-[50px] py-[3px] px-[30px] text-center text-white font-bold active:translate-y-[5px] mt-2 sm:text-[15px] sm:px-[10px] sm:mx-[10px] sm:w-28' disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}>
                        {isLastStep ? 'Registrarse' : 'Continuar'}
                      </Button>
                    </div>
                  </div>
                </FormElement>
                <div className='my-3 sm:w-full sm:text-center text-[10px]'>
                  <p className="text-center"> Al registrate aceptas los términos de servicio y la política de privacidad, incluida la política de uso de cookies</p>
                </div>
              </div>} />
        </div>
      </div>}
    </div>

  );
};
export default Register;