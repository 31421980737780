import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import LogoWeb from '../assets/img/logo-oha-education.png'
import LogoMovil from '../assets/img/logo-oha.png'
import Fondo from '../assets/background/fondo-logo-oha-education.png'
import AppleIcon from '../assets/icons/AppleIcon';
import FacebookIcon from '../assets/icons/FacebookIcon'
import GoogleIcon from '../assets/icons/GoogleIcon'
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios'
import { Form, FormElement } from "@progress/kendo-react-form";
import { Line } from '../assets/icons/Icons';
import { AUTH_OHA, API_OHA } from '../utils/urlConfig'
import AppContext from '../context/AppContext';
import Loading from '../components/Loading'
import '../assets/css/register.css'
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormPassword } from '../data/form-components';
import { passwordValidator, userNameValidator } from '../data/validators';

const Login = () => {
    const { loading } = useContext(AppContext)
    const [formState, setFormState] = React.useState({});
    const navigate = useNavigate()

    // validar información de formulario
    const onStepSubmit = React.useCallback(event => {
        const {
            isValid,
            values
        } = event;

        if (!isValid) {
            return;
        }

        setFormState(values);

        const setLoginData = async () => {
            console.log(values)
            const restAuth = await axios.post(`${AUTH_OHA}token/`, values)
            // retorna un JSON con la información del usuario
            const data = restAuth.data
            // token del usuario
            const ohaUserToken = data.data.token
            // Nombre de usuario
            const ohaUserName = data.data.username
            // Expiración del token
            const ohaDateExpiration = data.data.issued_at
            // almaceno info en localStorage
            localStorage.setItem('ohaUserToken', JSON.stringify(ohaUserToken))
            localStorage.setItem('ohaUserName', JSON.stringify(ohaUserName))
            localStorage.setItem('ohaDateExpiration', JSON.stringify(ohaDateExpiration))
            console.log(data.message);
            // Función valida que el usurio existe
            if (data.message === 'Login Successful') {
                try {
                    // obtengo el token del usuario
                    const userToken = JSON.parse(localStorage.getItem('ohaUserToken'))
                    // envio token del usuario a la API
                    const ohaEducationToken = await axios.post(`${API_OHA}token/`, { "authorization_code": userToken })
                    localStorage.setItem('ohaUserToken', JSON.stringify(ohaEducationToken.data.data.token))
                    localStorage.setItem('ohaDateExpiration', JSON.stringify(ohaEducationToken.data.data.issued_at))
                    console.log(ohaEducationToken)
                    navigate('/')
                } catch (err) {
                    console.log(err.response)
                }
            }
        }

        // ejecuta la función de login
        setLoginData();
    },
    );

    return (
        <div className="relative font-[lufga-light] bg-repeat-y bg-top bg-contain flex flex-col items-center justify-evenly h-[100vh] sm:h-[60vh]">
            {!loading && <div className='absolute z-[-10] h-[100vh] w-full overflow-hidden'>
                <img className='absolute rotate-[-25deg] opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading && <div className='absolute rotate-[-25deg] top-[15vh] right-22 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading &&
                <div className='relative flex flex-col items-center justify-center h-[100vh] w-[100vw]'>
                    <div>
                        <img className='w-[200px]' src={LogoMovil} alt='logo' />
                    </div>
                    <div className='absolute bottom-16 left-[44vw] sm:relative sm:left-0 sm:bottom-0 sm:top-5'>
                        <Loading />
                    </div>
                </div>}

            {!loading && <div className='flex items-center mt-[0vh] sm:mt-[40vh]'>
                <Link to="/">
                    <img className='w-[200px]' src={LogoWeb} alt='logo' />
                </Link>
            </div>
            }

            {!loading &&
                <div className='bg-white relative sm:top-14 w-full h-[58vh] flex flex-col items-center rounded-[25px] shadow-[0_-5px_5px_0_rgba(0,0,0,0.3)] sm:bottom-0 '>

                    <div className='relative top-5 w-full flex flex-col items-center'>
                        <div className='mt-[0px]'>
                            <Line />
                        </div>
                        <div className='flex flex-col items-center sm:mt-[6px] font-sans'>
                            <h1 className="font-semibold text-xl font-sans sm:text-2xl">Inicia Sesión</h1>
                            <p className="text-base sm:text-base mt-[1px] mb-[5px] text-neutral-500">Llena los datos para comenzar</p>
                        </div>

                        <Form
                            className='flex flex-col items-center w-full sm:mt-[15px] mt-[5px]'
                            initialValues={formState}
                            onSubmitClick={onStepSubmit}
                            render={formRenderProps =>
                                <div className='w-full flex flex-col flex-direction: row sm:mb-[15px]' id="main">
                                    <FormElement>
                                        <div className='flex flex-col items-center sm:flex-row py-[5px]'>
                                            <div className='flex flex-col items-center w-full sm:items-start sm:pr-[10px] pr-[0px]'>
                                                <Field key={'username'} id={'username'} name={'username'} label={'Usuario'} type={'text'} component={FormInput} validator={userNameValidator} placeholder="Ingrese su nombre de usuario" />
                                            </div>
                                            <div className=' w-full sm:items-start sm:pl-[10px] pl-[0px]'>
                                                <Field key={'password'} id={'password'} name={'password'} label={'Contraseña'} type={'password'} component={FormPassword} validator={passwordValidator} placeholder="Ingrese su contraseña" />
                                            </div>
                                        </div>
                                        <div className='relative flex justify-end w-full text-base text-neutral-400 text-[13px] sm:bottom-0 '>
                                            <button className='relative sm:right-0 text-[#03679e]' onClick={() => navigate('/recovery')} type='button'>Olvidé mi contraseña</button>
                                        </div>
                                        <div class="center tabs">
                                            <Button themeColor={'tertiary'} className='bg-sky-700  mb-[10px] rounded-[50px] py-[3px] px-[30px] text-center text-white font-bold active:translate-y-[5px] mt-2 sm:text-[15px] sm:px-[10px] sm:mx-[10px] sm:w-28' disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}>Ingresar</Button>
                                        </div>
                                    </FormElement>
                                </div>} />

                        <div className='flex text-[12px] mb-[10px] sm:mb-[10px]'>
                            <p className='mr-[5px] text-neutral-500 text-base sm:text-base'>¿No tienes una cuenta?</p>
                            <Link to="/register" className='text-amber-300 text-base font-bold sm:text-base'>Regístrate</Link>
                        </div>
                        <p className='text-neutral-500 text-base sm:text-base'>o inicia sesión con</p>
                        <div className='flex justify-evenly p-[10px]'>
                            <button className='w-10 mx-1'><FacebookIcon /></button>
                            <button className='w-10 flex items-center justify-center mx-1 bg-[#B0B0B0] rounded-full'><AppleIcon /></button>
                            <button className='w-10 mx-1'><GoogleIcon /></button>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default Login;