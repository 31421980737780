import * as React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, MaskedTextBox, Checkbox, RadioGroup, TextArea } from '@progress/kendo-react-inputs';
import { DatePicker, DateInput } from '@progress/kendo-react-dateinputs';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { CheckPassword } from '../assets/icons/Icons';

const showPassword = () => {
  const input = document.getElementById('password')
  if (input.type === 'password') {
    input.type = 'text'
  }
  else if (input.type === 'text') {
    input.type = 'password'
  }
}

const dateMask = (event) => { //Autocompletado de guiones en fechas
  const value = event.target.value
  console.log(value)
  if (value.match(/^\d{2}$/) !== null) {
      event.target.value = value + '-';
  } else if (value.match(/^\d{2}-\d{2}$/) !== null) {
      event.target.value = value + '-';
  }
  return value;
}

const capitalize = (event) => {
  const value = event.target.value
  console.log(value)
  event.target.value = value.charAt(0).toUpperCase() + value.slice(1);
  return value;
}

export const FormInput = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <Input valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
export const FormInputCapitalize = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <Input onKeyUp={capitalize} valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
export const FormDate = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <Input onKeyUp={dateMask} valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
export const FormPassword = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <Input valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      <button className='relative bottom-1 right-1' type="button" id="eye" onClick={showPassword}>
        <CheckPassword />
      </button>
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}

    </div>
  </FieldWrapper>;
};
export const FormCheckbox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Checkbox ariaDescribedBy={`${hintId} ${errorId}`} label={label} labelOptional={optional} valid={valid} id={id} disabled={disabled} {...others} />
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
  </FieldWrapper>;
};
export const FormMaskedTextBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <MaskedTextBox ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} {...others} />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
export const FormTextArea = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
    <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
    <TextArea valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
  </FieldWrapper>;
};
export const FormDropDownList = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
      {label}
    </Label>
    <DropDownList defaultItem="-- Seleccione --" ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
  </FieldWrapper>;
};
export const FormAutoComplete = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
      {label}
    </Label>
    <AutoComplete ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
  </FieldWrapper>;
};
export const FormDatePicker = fieldRenderProps => {
  const value = new Date();
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
      {label}
    </Label>
    <div className={'k-form-field-wrap'}>
      <DatePicker onKeyUp={dateMask} defaultValue={value} format="dd-MM-yyyy" 
       ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
      {showHint && <Hint id={hintId} direction={hintDirection}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
export const FormDateInput = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
      {label}
    </Label>
    <DateInput ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
    {showHint && <Hint id={hintId}>{hint}</Hint>}
    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
  </FieldWrapper>;
};
export const FormRadioGroup = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <RadioGroup
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        valid={valid}
        disabled={disabled}
        ref={editorRef}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};