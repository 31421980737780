import * as React from 'react';
import { Line } from '../assets/icons/Icons';
import { FormCheckbox } from '../data/form-components';
import { requiredValidator, termsValidator } from '../data/validators';
import { Field } from '@progress/kendo-react-form';

export const TerminosCondiciones = <div>
  <div className='flex flex-col items-center mb-[0px] mt-[20px] sm:mt-[35px]'>
    <h1 className='font-semibold text-xl font-sans sm:text-2xl'>Términos y condiciones</h1>
    <p className='text-base sm:text-base mt-[1px] mb-[5px] text-neutral-500'>Lea los términos y TerminosCondiciones</p>
    <div className='z-40'>
      <Line />
    </div>
  </div>
  <div class="center tabs">
    <Field key={"check"} id={"check"} name={"check"} label={"Acepto los términos de servicio y política de privacidad"} type={"checkbox"} component={FormCheckbox} validator={termsValidator} />
  </div>
</div >;