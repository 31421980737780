import { getter } from '@progress/kendo-react-common';
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);
// Datos escenciales
export const firstNameValidator = value => !value ? "El campo nombre es requerido." : "";
export const lastNameValidator = value => !value ? "El campo apellido es requerido." : "";
export const userNameValidator = value => !value ? "El campo usuario es requerido." : "";
export const requiredValidator = value => value ? "" : "El campo es requerido.";
export const passwordValidator = value => !value ? "El campo contraseña es requerido." : value && value.length >= 7 ? '' : 'Usa 7 o más caracteres con una combinación de números y letras.';
export const passwordValidator2 = value => !value ? "El campo contraseña es requerido." : value && value.length >= 7 ? '' : 'Usa 7 o más caracteres con una combinación de números y letras.';
// Datos adicionales
export const emailValidator = value => !value ? "El correo es requerido." : emailRegex.test(value) ? "" : "El correo ingresado no es válido.";
export const requiredDate = value => !value ? "Ingrese una fecha diferente a la actual" : "";
export const phoneValidator = value => !value ? "El número de teléfono es requerido." : phoneRegex.test(value) ? "" : "No es un teléfono válido, verificar.";
// terminos y condiciones
export const termsValidator = value => value ? "" : "Se requiere estar de acuerdo con los Términos y Condiciones.";
const userNameGetter = getter('username');
const emailGetter = getter('email');
export const formValidator = values => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: 'Por favor complete los siguientes campos.',
    ['username']: !userName ? 'Se requiere nombre de usuario.' : '',
    ['email']: emailValue && emailRegex.test(emailValue) ? '' : 'El correo electrónico es obligatorio y debe estar en un formato válido.'
  };
};