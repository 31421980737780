import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { genders } from '../data/data';
import { FormInput, FormDatePicker, FormMaskedTextBox, FormDropDownList, FormRadioGroup, FormDateInput, FormDate } from '../data/form-components';
import { emailValidator, requiredDate, phoneValidator, requiredValidator } from '../data/validators';

export const DatosAdicionales = <div>
  <div className='flex flex-col items-center sm:flex-row py-[5px]'>
    <div className='flex flex-col items-center w-full sm:items-start sm:pr-[7px] pr-[0px]'>
      <Field key={"email"} id={"email"} name={"email"} label={"Correo electrónico"} type={"email"} component={FormInput} validator={emailValidator} placeholder="Ingrese su correo" />
    </div>

    <div className='flex flex-col items-center w-full sm:items-start sm:pl-[7px] pl-[0px]'>
      <Field key={"birth_date"} id={"birth_date"} name={"birth_date"} label={"Fecha de nacimiento"} type={'text'} component={FormDate} validator={requiredDate} placeholder='dd-mm-aaaa' />
      {/* <Field key={"birth_date"} id={"birth_date"} name={"birth_date"} label={"Fecha de nacimiento"} type={'text'} component={FormDatePicker} validator={requiredDate} /> */}
    </div>
  </div>

  <div className='flex flex-col items-center sm:flex-row py-[5px]'>
    <div className='flex flex-col items-center w-full sm:items-start sm:pr-[7px] pr-[0px]'>
    <Field key={'phone'} id={'phone'} name={'phone'} label={'Número de teléfono'} mask={'+58 000 00000 00'} component={FormMaskedTextBox} validator={phoneValidator} placeholder='(+58) 000 00000 00'/>
    </div>

    {/* trae valores desde data.jsx */}
    <div className='flex flex-col items-center w-full sm:items-start sm:pl-[7px] pl-[0px]'>
    <Field
      key={"gender"}
      id={"gender"}
      name={"gender"}
      label={"Género"}
      layout={"horizontal"}
      component={FormRadioGroup}
      data={genders}
      validator={requiredValidator}
    />
    </div>
  </div>
</div>;