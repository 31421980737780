export const genders = [
  {
    label: 'Hombre',
    value: 'male'
  }, {
    label: 'Mujer',
    value: 'female'
  }, {
    label: 'Otro',
    value: 'other'
  }];
export const countries = [
  "Afganistán",
  "Albania",
  "Andorra",
  "Alemania",
  "Angola",
  "Anguila",
  "Antigua y Barbuda",
  "Antillas holandesas",
  "Arabia Saudí",
  "Argelia",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Bahrein",
  "Bangladesh",
  "Barbados",
  "Bélgica",
  "Belice",
  "Benín",
  "Bermudas",
  "Bhután",
  "Bielorrusia",
  "Birmania",
  "Bolivia",
  "Bosnia y Herzegovina",
  "Botsuana",
  "Brasil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Camboya",
  "Camerún",
  "Canadá",
  "Chad",
  "Chile",
  "China",
  "Chipre",
  "Ciudad estado del Vaticano",
  "Colombia",
  "Comores",
  "Congo",
  "Corea del Norte",
  "Costa del Marfíl",
  "Costa Rica",
  "Croacia",
  "Cuba",
  "Dinamarca",
  "Djibouri",
  "Dominica",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Emiratos Arabes Unidos",
  "Eritrea",
  "Eslovaquia",
  "Eslovenia",
  "España",
  "Estados Unidos",
  "Estonia",
  "Filipinas",
  "Finlandia",
  "Francia",
  "Gambia",
  "Georgia",
  "Granada",
  "Grecia",
  "Groenlandia",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guayana",
  "Guayana francesa",
  "Guinea",
  "Guinea Ecuatorial",
  "Guinea-Bissau",
  "Haití",
  "Holanda",
  "Honduras",
  "Hungría",
  "India",
  "Indonesia",
  "Irak",
  "Irán",
  "Irlanda",
  "Isla Bouvet",
  "Isla Christmas",
  "Islandia",
  "Islas Caimán",
  "Israel",
  "Italia",
  "Jamaica",
  "Japón",
  "Jordania",
  "Kazajistán",
  "Kenia",
  "Kirguizistán",
  "Kiribati",
  "Kuwait",
  "Laos",
  "Lesoto",
  "Letonia",
  "Líbano",
  "Liberia",
  "Libia",
  "Madagascar",
  "Malasia",
  "México",
  "Micronesia",
  "Moldavia",
  "Mónaco",
  "Mongolia",
  "Montserrat",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Niue",
  "Norfolk",
  "Noruega",
  "Omán",
  "Panamá",
  "Paquistán",
  "Paraguay",
  "Perú",
  "Pitcairn",
  "Polinesia francesa",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reino Unido",
  "República Centroafricana",
  "República Checa",
  "República de Sudáfrica",
  "República Dominicana",
  "Reunión",
  "Ruanda",
  "Rumania",
  "Rusia",
  "Samoa",
  "Suecia",
  "Suiza",
  "Surinam",
  "Tayikistán",
  "Trinidad y Tobago",
  "Uganda",
  "Uruguay",
  "Uzbekistán",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabue",
];