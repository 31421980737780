import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Loading = () => {
  return (
    <div>
      <FontAwesomeIcon className='fa-spin-pulse text-[#E4BF4C] text-[50px]' icon={faSpinner}/>
    </div>
  );
};

export default Loading;