import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import LogoWeb from '../assets/img/logo-oha-education.png'
import LogoMovil from '../assets/img/logo-oha.png'
import Fondo from '../assets/background/fondo-logo-oha-education.png'
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Line } from '../assets/icons/Icons';
import { AUTH_OHA, API_OHA } from '../utils/urlConfig'
import AppContext from '../context/AppContext';
import Loading from '../components/Loading'
import '../assets/css/register.css'
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormPassword } from '../data/form-components';
import { passwordValidator, passwordValidator2 } from '../data/validators';

const Restore = () => {
    const { loading } = useContext(AppContext)
    const [formState, setFormState] = React.useState({});
    const navigate = useNavigate()

    // validar información de formulario
    const onStepSubmit = React.useCallback(event => {
        const {
            isValid,
            values
        } = event;

        if (!isValid) {
            return;
        }

        setFormState(values);

        const setRecoveryData = async () => {
            console.log(values)

        }

        // ejecuta la función de login
        setRecoveryData();
    },
    );

    return (
        <div className="relative font-[lufga-light] bg-repeat-y bg-top bg-contain flex flex-col items-center justify-evenly h-[100vh] sm:h-[60vh]">
            {!loading && <div className='absolute z-[-10] h-[100vh] w-full overflow-hidden'>
                <img className='absolute rotate-[-25deg] opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading && <div className='absolute rotate-[-25deg] top-[15vh] right-22 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading &&
                <div className='relative flex flex-col items-center justify-center h-[100vh] w-[100vw]'>
                    <div>
                        <img className='w-[200px]' src={LogoMovil} alt='logo' />
                    </div>
                    <div className='absolute bottom-16 left-[44vw] sm:relative sm:left-0 sm:bottom-0 sm:top-5'>
                        <Loading />
                    </div>
                </div>}

            {!loading && <div className='flex items-center mt-[0vh] sm:mt-[40vh]'>
                <Link to="/">
                    <img className='w-[200px]' src={LogoWeb} alt='logo' />
                </Link>
            </div>
            }

            {!loading &&
                <div className='bg-white relative sm:top-14 w-full h-[58vh] flex flex-col items-center rounded-[25px] shadow-[0_-5px_5px_0_rgba(0,0,0,0.3)] sm:bottom-0 '>

                    <div className='relative w-full flex flex-col items-center'>
                        <div className='sm:mt-[50px] flex flex-col items-center mb-[0px] mt-[50px] sm:mt-[35px]'>
                            <h1 className='font-semibold text-xl font-sans sm:text-2xl'>Restablecer contraseña</h1>
                            <p className='sm:text-sm text-xs mt-[8px] mb-[3px] text-neutral-500 sm:w-[450px] w-[300px] text-center'>Ingrese la nueva contraseña para acceder a su cuenta</p>
                            <div className='z-40'>
                                <Line />
                            </div>
                        </div>

                        <Form
                            className='flex flex-col items-center w-full sm:mt-[15px] mt-[5px]'
                            initialValues={formState}
                            onSubmitClick={onStepSubmit}
                            render={formRenderProps =>
                                <div className='w-full flex flex-col flex-direction: row sm:mb-[50px]' id="main">
                                    <FormElement>
                                        <div className='flex flex-col items-center sm:flex-row py-[5px]'>
                                            <div className=' w-full sm:items-start sm:pl-[10px] pl-[0px]'>
                                                <Field key={'new_password'} id={'new_password'} name={'password'} label={'Contraseña'} type={'password'} component={FormPassword} validator={passwordValidator} placeholder="Ingrese su contraseña" />
                                            </div>
                                            <div className=' w-full sm:items-start sm:pl-[10px] pl-[0px]'>
                                                <Field key={'new_password'} id={'new_password'} name={'new_password'} label={'Repetir contraseña'} type={'password'} component={FormPassword} validator={passwordValidator2} placeholder="Repita su contraseña" />
                                            </div>
                                        </div>
                                        <div class="center tabs">
                                            <Button themeColor={'tertiary'} className='bg-sky-700  mb-[10px] rounded-[50px] py-[3px] px-[30px] text-center text-white font-bold active:translate-y-[5px] mt-2 sm:text-[15px] sm:px-[10px] sm:mx-[10px] sm:w-28' disabled={!formRenderProps.allowSubmit} onClick={formRenderProps.onSubmit}>Recuperar</Button>
                                        </div>
                                    </FormElement>
                                </div>} />
                    </div>
                </div>}
        </div>
    );
};

export default Restore;