import React, { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext';
import Logo from '../assets/img/logo-oha-education.png'
import Fondo from '../assets/background/fondo-logo-oha-education.png'
import axios from 'axios'
import { ArrowBack, Line } from '../assets/icons/Icons';
import { AUTH_OHA, API_OHA } from '../utils/urlConfig'
import Loading from '../components/Loading';
import { useForm } from "react-hook-form";

const Confirm = () => {
    const navigate = useNavigate()
    const { loading, setLoading } = useContext(AppContext)
    const form = useRef(null)
    const { register, formState: { errors }, handleSubmit } = useForm();

    const send = async (event) => {
        const formData = new FormData(form.current);
        const Data = {
            activation_code: formData.get('activation_code'),
            email: JSON.parse(localStorage.getItem('ohaActivationEmail'))
        }
        const Data2 = {
            username: JSON.parse(localStorage.getItem('ohaUserName')),
            password: JSON.parse(localStorage.getItem('ohaPassword'))
        }
        console.log(Data)

        try {
            const response = await axios.post(`${AUTH_OHA}token/?activate=true`, Data)
            const data = response.data
            console.log(data)
            const token = data.data
            console.log(token)
            localStorage.removeItem('ohaActivationEmail')
            try {
                setLoading(true)
                const response = await axios.post(`${AUTH_OHA}token/`, Data2)
                const data = response.data
                console.log(data)
                const ohaAuthenticationToken = data.data.token
                const ohaAuthenticationUserName = data.data.username
                const ohaAuthenticationExpiration = data.data.issued_at
                // console.log(token)
                localStorage.setItem('ohaAuthenticationUserName', JSON.stringify(ohaAuthenticationUserName))
                localStorage.setItem('ohaAuthenticationToken', JSON.stringify(ohaAuthenticationToken))
                localStorage.setItem('ohaAuthenticationExpiration', JSON.stringify(ohaAuthenticationExpiration))
                // console.log(localStorage.getItem('tokenAuth'))
                if (data.message === 'Login Successful') {
                    try {
                        const authToken = JSON.parse(localStorage.getItem('ohaAuthenticationToken'))
                        const responseEdu = await axios.post(`${API_OHA}token/`, { "authorization_code": authToken })
                        console.log(responseEdu)
                        localStorage.setItem('ohaEducationToken', JSON.stringify(responseEdu.data.data.token))
                        localStorage.setItem('ohaEducationExpiration', JSON.stringify(responseEdu.data.data.issued_at))
                        setLoading(false)
                        navigate('/')
                    } catch (err) {
                        setLoading(false)
                        console.log(err.response)
                        if (err.response.status === 500) {
                            alert('Lo sentimos, intente mas tarde')
                        }
                    }
                }
            } catch (error) {
                setLoading(false)
                console.log(error.response)
                if (error.response.data.status === false) {
                    if (error.response.data.message === 'User not active') {
                        alert('Access denied, please confirm your profile')
                        return
                    }
                    if (error.response.data.message === 'Unable to log in with provided credentials.') {
                        alert('Usuario o Contraseña incorrecta')
                        return
                    }
                }
            }
        } catch (err) {
            console.log(err.response)
        }
    }

    window.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
            handleSubmit()
        }
    })

    return (
        <div className="relative font-[lufga-light bg-repeat-y bg-center bg-contain flex flex-col items-center justify-evenly h-screen overflow-hidden">
            {!loading && <div className='absolute rotate-[-25deg] top-12 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading && <div className='absolute rotate-[-25deg] top-[15vh] right-22 z-[-10] overflow-hidden'>
                <img className='opacity-10 object-contain' src={Fondo} alt='fondo' />
            </div>}

            {loading &&
                <div className='relative flex flex-col items-center justify-center h-[100vh] w-[100vw]'>
                    <div>
                        <img className='w-[200px]' src={Logo} alt='logo' />
                    </div>
                    <div className='absolute bottom-16 left-[44vw] sm:relative sm:left-0 sm:bottom-0 sm:top-5'>
                        <Loading />
                    </div>
                </div>}

            {!loading && <div className='mb-[0px] sm:mt-[90px] mt-[50px]'>
                <button type='button' onClick={() => navigate('/')}>
                    <img className='w-[200px]' src={Logo} alt='logo' />
                </button>
            </div>} 

            {!loading && <div className='sm:mt-[50px] mt-[30px] relative bg-white w-screen h-[100%] flex flex-col items-center rounded-[25px] shadow-[0_-5px_5px_0_rgba(0,0,0,0.3)]'>
                <div className='sm:mt-[50px] flex flex-col items-center mb-[0px] mt-[50px] sm:mt-[35px]'>
                    <h1 className='font-semibold text-xl font-sans sm:text-2xl'>Código de Activación</h1>
                    <p className='sm:text-sm text-xs mt-[8px] mb-[3px] text-neutral-500 sm:w-[450px] w-[300px] text-center'>Para poder iniciar sesión debes primero activar tu cuenta con un código que se ha enviado a tu correo</p>
                    <div className='z-40'>
                        <Line />
                    </div>
                </div>
                <form onSubmit={handleSubmit(send)} className='flex flex-col items-center mt-[15px] w-full' ref={form}>
                    <div className='flex flex-col items-center w-full sm:items-start  sm:w-1/4 sm:mx-[15px]'>
                        <fieldset className={`border-[1px] border-neutral-800 rounded-xl w-10/12 sm:w-full focus-within:border-amber-400 ${errors.code && "invalid"}`}>
                            <legend className='rounded text-sm ml-[15px] px-1'>Código</legend>
                            <input
                                type="text"
                                id="activation_code"
                                className='w-full h-full border-neutral-800 focus:outline-0 rounded-xl px-[20px] pt-[0px] pb-[10px] sm:w-full'
                                {...register("code", { required: "El campo es requerido." })}
                                placeholder="Ingrese su código de activación"
                            />
                        </fieldset>
                        {errors.code && (
                            <small className="text-danger">{errors.code.message}</small>
                        )}
                    </div>
                    <div className='flex justify-center my-5'>
                        <button className='bg-sky-700 sm:text-sm text-xs text-center sm:mt-[30px] mt-[5px] text-white rounded-[50px] my-[5px] py-[5px] px-[20px] font-bold' type='submit'>Recuperar</button>
                    </div>
                </form>
            </div>}
        </div>
    );
};

export default Confirm;